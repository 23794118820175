import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import ClientForm from '@/views/Dashboard/Clients/Forms/ClientForm.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import VueStore from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useClient, useConfirmModal } from '@/composables';
import { useAttachment } from '@/composables/use-attachment';
import { deleteOrganization } from '@/api/requests/organizations';
export default defineComponent({
    name: 'EditClient',
    components: {
        Breadcrumbs,
        Btn,
        ClientForm,
        Tabs,
        Tab,
        WithSideNavigations,
        FileUploader,
        IconSpinner,
        ModalWrapper,
    },
    async beforeRouteEnter(to, from, next) {
        await Promise.all([
            VueStore.dispatch('client/fetchClientDetails', to.params['id']),
            VueStore.dispatch('attachment/fetchAttachments', {
                id: to.params['id'],
                target: 'organizations',
                type: 'document',
            }),
        ]);
        next();
    },
    setup(_, ctx) {
        const { client } = useClient();
        const { attachments, files } = useAttachment(ctx);
        const { getConfirmModalObject } = useConfirmModal(ctx);
        const loading = ref(false);
        const form = ref(null);
        const docs = computed(() => {
            return [...files.value, ...attachments.value.document];
        });
        const clickUpdate = () => {
            form.value?.handleUpdate();
        };
        const updateLoading = (value) => {
            loading.value = value;
        };
        const onClientDelete = async () => {
            try {
                await deleteOrganization(client.value.id);
                ctx.root.$toast.success(ctx.root.$t('clients-view.deleted-successfully'));
            }
            finally {
                ctx.root.$router.push({ name: 'Clients' });
            }
        };
        return {
            client,
            loading,
            form,
            docs,
            attachments,
            updateLoading,
            getConfirmModalObject,
            clickUpdate,
            onClientDelete,
        };
    },
});
