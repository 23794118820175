var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
return [_c('image-uploader',{staticClass:"mt-6",attrs:{"padding":"px-4"},model:{value:(_vm.logoUrl),callback:function ($$v) {_vm.logoUrl=$$v},expression:"logoUrl"}}),_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.client-name'),"placeholder":_vm.$t('client-form.client-name-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.organizationForm.name),callback:function ($$v) {_vm.$set(_vm.organizationForm, "name", $$v)},expression:"organizationForm.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.short-name'),"placeholder":_vm.$t('client-form.short-name-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.organizationForm.shortName),callback:function ($$v) {_vm.$set(_vm.organizationForm, "shortName", $$v)},expression:"organizationForm.shortName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.nip'),"placeholder":_vm.$t('client-form.nip-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.organizationForm.nip),callback:function ($$v) {_vm.$set(_vm.organizationForm, "nip", $$v)},expression:"organizationForm.nip"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|length:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.regon'),"placeholder":_vm.$t('client-form.regon-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.organizationForm.regon),callback:function ($$v) {_vm.$set(_vm.organizationForm, "regon", $$v)},expression:"organizationForm.regon"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"numeric|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.krs'),"placeholder":_vm.$t('client-form.krs-placeholder'),"error-message":errors[0]},model:{value:(_vm.organizationForm.krs),callback:function ($$v) {_vm.$set(_vm.organizationForm, "krs", $$v)},expression:"organizationForm.krs"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.address-hq'),"placeholder":_vm.$t('client-form.street-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.organizationForm.address),callback:function ($$v) {_vm.$set(_vm.organizationForm, "address", $$v)},expression:"organizationForm.address"}})]}}],null,true)}),_c('div',{staticClass:"flex w-full gap-4"},[_c('ValidationProvider',{staticClass:"flex-auto",attrs:{"rules":{ regex: _vm.postalCodeRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mb-5",attrs:{"error-message":errors[0],"placeholder":_vm.$t('client-form.postcode-placeholder')},model:{value:(_vm.organizationForm.postalCode),callback:function ($$v) {_vm.$set(_vm.organizationForm, "postalCode", $$v)},expression:"organizationForm.postalCode"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"flex-auto",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mb-5",attrs:{"placeholder":_vm.$t('client-form.city-placeholder'),"error-message":errors[0]},model:{value:(_vm.organizationForm.city),callback:function ($$v) {_vm.$set(_vm.organizationForm, "city", $$v)},expression:"organizationForm.city"}})]}}],null,true)})],1),_c('div',{staticClass:"block mb-6 mt-6"},[_c('label',{staticClass:"toggle-label flex items-center"},[_c('span',{staticClass:"my-1 w-48 inline-block font-semibold whitespace-pre"},[_vm._v(" "+_vm._s(_vm.$t('client-form.invoice'))+" ")]),_c('switch-input',{model:{value:(_vm.organizationForm.hasInvoiceData),callback:function ($$v) {_vm.$set(_vm.organizationForm, "hasInvoiceData", $$v)},expression:"organizationForm.hasInvoiceData"}})],1)]),(_vm.organizationForm.hasInvoiceData)?_c('div',[_c('ValidationProvider',{attrs:{"rules":{ required: _vm.organizationForm.hasInvoiceData }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.client-name'),"placeholder":_vm.$t('client-form.client-name-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.extendedForm.invoiceProperName),callback:function ($$v) {_vm.$set(_vm.extendedForm, "invoiceProperName", $$v)},expression:"extendedForm.invoiceProperName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{
          numeric: true,
          length: 10,
          required: _vm.organizationForm.hasInvoiceData,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.nip'),"placeholder":_vm.$t('client-form.nip-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.extendedForm.invoiceNip),callback:function ($$v) {_vm.$set(_vm.extendedForm, "invoiceNip", $$v)},expression:"extendedForm.invoiceNip"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: _vm.organizationForm.hasInvoiceData }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full mb-5",attrs:{"label":_vm.$t('client-form.address'),"placeholder":_vm.$t('client-form.street-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.extendedForm.invoiceAddress),callback:function ($$v) {_vm.$set(_vm.extendedForm, "invoiceAddress", $$v)},expression:"extendedForm.invoiceAddress"}})]}}],null,true)}),_c('div',{staticClass:"flex w-full"},[_c('ValidationProvider',{attrs:{"rules":{
            regex: _vm.postalCodeRegex,
            required: _vm.organizationForm.hasInvoiceData,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('text-input',{staticClass:"mb-5 mr-4",attrs:{"placeholder":_vm.$t('client-form.postcode-placeholder'),"error-message":errors[0]},model:{value:(_vm.extendedForm.invoicePostalCode),callback:function ($$v) {_vm.$set(_vm.extendedForm, "invoicePostalCode", $$v)},expression:"extendedForm.invoicePostalCode"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: _vm.organizationForm.hasInvoiceData }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('text-input',{staticClass:"mb-5 flex-grow",attrs:{"placeholder":_vm.$t('client-form.city-placeholder'),"error-message":errors[0]},model:{value:(_vm.extendedForm.invoiceCity),callback:function ($$v) {_vm.$set(_vm.extendedForm, "invoiceCity", $$v)},expression:"extendedForm.invoiceCity"}})]}}],null,true)})],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }