var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"view-width":50,"side-bar-width":50,"side":"right","footer":""},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(){return [_c('div',[_c('tabs',[(_vm.attachments.document)?_c('tab',{attrs:{"name":_vm.$t('edit-client-view.documents'),"selected":""}},[_c('file-uploader',{attrs:{"type":"document","target":"clients","list":_vm.docs}})],1):_vm._e()],1)],1)]},proxy:true},(_vm.client)?{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('btn',{staticClass:"w-24 md:w-48 md:mr-3",attrs:{"disabled":_vm.loading,"is-loading":_vm.loading},on:{"click":_vm.clickUpdate}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('common.save')))]):_vm._e()]),_c('modal-wrapper',{staticClass:"cursor-pointer",attrs:{"content":_vm.getConfirmModalObject('client', _vm.client.name)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var runWithModal = ref.runWithModal;
return [_c('btn',{staticClass:"w-auto md:w-48 mx-3",attrs:{"disabled":_vm.loading,"is-loading":_vm.loading,"theme":"secondary"},nativeOn:{"click":function($event){runWithModal(
                _vm.onClientDelete,
                _vm.getConfirmModalObject('client', _vm.client.name)
              )}}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('edit-client-view.remove')))]):_vm._e()])]}}],null,false,3486355020)})],1),_c('router-link',{attrs:{"to":{ name: 'ClientDetails', params: { id: _vm.$route.params.id } }}},[_c('btn',{attrs:{"theme":"none"}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)]},proxy:true}:null],null,true)},[(_vm.client)?_c('div',[_c('breadcrumbs',{attrs:{"path":[
        {
          name: ("" + (_vm.$t('edit-client-view.clients'))),
          url: { name: 'Clients' },
        },
        { name: ("" + (_vm.$t('edit-client-view.edit-client'))) },
        { name: _vm.client.name } ]}}),_c('tabs',{staticClass:"mt-4.5 mb-16"},[_c('tab',{staticClass:"pb-24",attrs:{"name":_vm.$t('edit-client-view.client-details'),"selected":""}},[_c('client-form',{ref:"form",attrs:{"client":_vm.client},on:{"updateLoading":_vm.updateLoading}})],1)],1)],1):_c('icon-spinner',{staticClass:"spinner stroke-primary m-auto",attrs:{"width":"64px","height":"64px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }