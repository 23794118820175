import { computed, defineComponent, ref } from '@vue/composition-api';
import { createOrganization, updateOrganization, addOrganizationAttachments, OrganizationType, } from '@/api/requests/organizations';
import { deleteAttachments } from '@/api/requests/attachments';
import { TextInput } from '@/components/Inputs';
import { SwitchInput } from '@/components/Inputs';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { postalCodeRegex } from '@/util/form';
import { useValidation } from '@/composables';
import { useAttachment } from '@/composables/use-attachment';
export default defineComponent({
    components: {
        TextInput,
        SwitchInput,
        ValidationProvider,
        ValidationObserver,
        ImageUploader,
    },
    props: {
        client: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, ctx) {
        useValidation(ctx);
        const { files, filesToDelete, clearUploadQueue, clearDeleteQueue } = useAttachment(ctx);
        const validator = ref(null);
        const form = ref(null);
        const organizationForm = ref({
            name: props.client?.name || '',
            shortName: props.client?.shortName || '',
            nip: props.client?.nip || '',
            regon: props.client?.regon || '',
            address: props.client?.address || '',
            postalCode: props.client?.postalCode || '',
            city: props.client?.city || '',
            krs: props.client?.krs || null,
            hasInvoiceData: props.client?.hasInvoiceData || false,
        });
        const extendedForm = ref({
            invoiceProperName: props.client?.invoiceProperName || '',
            invoiceNip: props.client?.invoiceNip || '',
            invoiceAddress: props.client?.invoiceAddress || '',
            invoicePostalCode: props.client?.invoicePostalCode || '',
            invoiceCity: props.client?.invoiceCity || '',
        });
        const logoUrl = ref(props.client?.logoUrl || '');
        const clientLogo = computed(() => {
            if (props.client?.logoUrl && !logoUrl.value)
                return null;
            if (props.client?.logoUrl &&
                logoUrl.value &&
                logoUrl.value !== props.client?.logoUrl)
                return logoUrl.value;
            if (!props.client?.logoUrl && logoUrl.value)
                return logoUrl.value;
            return undefined;
        });
        const formData = computed(() => {
            const data = {
                ...organizationForm.value,
                ...(!props.client?.type && { type: OrganizationType.CLIENT }),
                ...(organizationForm.value.hasInvoiceData ? extendedForm.value : null),
                logoUrl: clientLogo.value,
            };
            if (data.krs === '') {
                if (props.client.krs)
                    data.krs = null;
                // @ts-ignore
                else
                    delete data.krs;
            }
            return data;
        });
        const send = async (redirect) => {
            ctx.emit('updateLoading', true);
            try {
                const response = await createOrganization(formData.value);
                if (files.value.length) {
                    await addOrganizationAttachments(response.id, files.value);
                }
                ctx.emit('updateLoading', false);
                ctx.root.$toast.success(ctx.root.$t('client-form.client-added'));
                if (redirect) {
                    ctx.root.$router.push({ name: 'Clients' });
                }
                else {
                    clearForm();
                    clearUploadQueue();
                    clearDeleteQueue();
                }
            }
            catch {
                ctx.emit('updateLoading', false);
            }
        };
        const handleSave = async () => {
            // @ts-ignore
            const isValid = await validator.value.validate();
            if (isValid) {
                send(true);
            }
        };
        const handleSaveAndNext = async () => {
            // @ts-ignore
            const isValid = await validator.value.validate();
            if (isValid) {
                send(false);
            }
        };
        const handleUpdate = async () => {
            // @ts-ignore
            const isValid = await validator.value.validate();
            if (isValid) {
                ctx.emit('updateLoading', true);
                try {
                    await updateOrganization(ctx.root.$route.params.id, formData.value);
                    if (files.value.length) {
                        await addOrganizationAttachments(files.value, props.client.id);
                    }
                    if (filesToDelete.value.length) {
                        await deleteAttachments(filesToDelete.value);
                    }
                    ctx.emit('updateLoading', false);
                    ctx.root.$toast.success(ctx.root.$t('client-form.client-updated'));
                    ctx.root.$router.go(-1);
                }
                catch {
                    ctx.emit('updateLoading', false);
                }
            }
        };
        const clearForm = () => {
            organizationForm.value = {
                name: '',
                shortName: '',
                nip: '',
                regon: '',
                address: '',
                postalCode: '',
                city: '',
                hasInvoiceData: false,
                krs: '',
                type: OrganizationType.CLIENT,
            };
            extendedForm.value = {
                invoiceProperName: '',
                invoiceNip: '',
                invoiceAddress: '',
                invoicePostalCode: '',
                invoiceCity: '',
            };
            logoUrl.value = '';
            ctx.root.$nextTick(() => {
                form.value.reset();
            });
        };
        return {
            validator,
            form,
            postalCodeRegex,
            organizationForm,
            extendedForm,
            logoUrl,
            handleSave,
            handleSaveAndNext,
            handleUpdate,
        };
    },
});
